.controller {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    background-image: url(../../assets/img/bg-white.svg);
    background-repeat: no-repeat;
    background-position: top right;
    padding-top: 173px;
}

.title {
    font-size: 36px;
    color: #303030;
    line-height: 50px;
    font-weight: 700;

    div {
        font-size: 24px;
    }
}

.button {
    position: absolute;
    width: calc(100% - 48px);
    margin: auto;
    left: 0;
    right: 0;
    bottom: 60px;
    height: 151px;
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1px;
    font-weight: 700;
    text-align: center;
    background-color: #00AF20;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.offButton {
    position: absolute;
    width: calc(100% - 48px);
    margin: auto;
    left: 0;
    right: 0;
    bottom: 216px;
    height: 80px;
    line-height: 80px;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 700;
    text-align: center;
    background-color: #FE3B35;
    border-radius: 4px;
}

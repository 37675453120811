.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 84px 24px 0;

    &.centered {
        padding: 84px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.questionsCount {
    font-size: 14px;
    line-height: 18px;
    color: #303030;
    font-weight: 700;
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: auto;
}

.questionText {
    width: 100%;
    color: #3F3F3F;
    font-size: 28px;
    line-height: 38px;
    font-family: 'Noto Serif', serif;
}

.resultContainer {
    text-align: left;
}

.correctTitle {
    font-size: 32px;
    line-height: 42px;
    color: #00BE04;
    margin-bottom: 9px;
    font-family: 'Noto Serif', serif;
}

.desciption {
    font-size: 14px;
    line-height: 20px;
    color: #303030;

    span {
        font-weight: 700;
    }
}

.wrongTitle {
    font-size: 32px;
    line-height: 42px;
    color: #FC2C2A;
    margin-bottom: 9px;
    font-family: 'Noto Serif', serif;
}

.answersWrapper {
    width: calc(100% - 48px);
    padding: 26px 16px;
    box-shadow: 0 10px 30px #B1B1B147;
    box-sizing: border-box;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: auto;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/img/bg-white.svg);
    background-repeat: no-repeat;
    background-position: top right;

    .eventName {
        font-size: 14px;
        line-height: 18px;
        color: #303030;
        font-weight: 700;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
    }
    
    .icon{
        margin-bottom: 20px;
        width: 238px;
    }

    .title{
        font-size: 40px;
        line-height: 49px;
        color: #414141;
        margin-bottom: 8px;
        font-weight: 700;
        width: calc(100% - 48px);
    }

    .subtitle{
        font-size: 14px;
        line-height: 20px;
        color: #414141;
        margin-bottom: 8px;
        width: calc(100% - 48px);
    }
}
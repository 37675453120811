.optionContainer {
    margin-bottom: 28px;
    width: 400px;
    height: 72px;
    position: relative;
    
    .inner {
        position: absolute;
        height: 100%;
        width: 0;
        top: 0;
        left: 0;
        border-radius: 4px;
        z-index: 2;
        background-color: rgba(26, 26, 29, 0.1);
        transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
}

.option {
    width: 400px;
    height: 72px;
    line-height: 68px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid #D5D5D5;
    color: #1A1A1D;
    padding-left: 18px;

    &:nth-child(n+3) {
        margin-bottom: 0;
    }

    &.correct {
        border-color: #00B507;
        background-color: #00B507;
        color: #fff;

        .percent {
            color: #fff;
            font-weight: 700;
        }
    }
}

.letter {
    font-size: 24px;
    margin-right: 16px;
    display: inline-block;
    line-height: 68px;
    vertical-align: top;
}

.text {
    font-size: 24px;
    display: inline-block;
    font-weight: 700;
    line-height: 68px;
    vertical-align: top;
}

.percent {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 20px;
    font-size: 24px;
    color: #1A1A1D;
    z-index: 3;
}
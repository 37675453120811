.timeoutScreen {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 40px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventName {
    font-size: 14px;
    line-height: 18px;
    color: #303030;
    font-weight: 700;
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: auto;
}

.greetings {
    font-size: 30px;
    line-height: 42px;
    color: #3F3F3F;
    margin-bottom: 12px;
    font-family: 'Noto Serif', serif;
    padding: 0 20px;
    box-sizing: border-box;
}

.results {
    font-size: 30px;
    line-height: 42px;
    color: #3F3F3F;
    margin-bottom: 12px;
    font-family: 'Noto Serif', serif;
    margin-bottom: 40px;

    span {
        color: #FC2C2A;
        font-size: 52px;
    }
}
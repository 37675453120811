.container {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    background-image: url(../../assets/img/bg-white.svg);
    background-repeat: no-repeat;
    background-position: top right;
}

.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .eventName {
        font-size: 14px;
        line-height: 18px;
        color: #303030;
        font-weight: 700;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .circle {
        background-color: rgba(26, 26, 29, 0.02);
        border-radius: 100%;
        height: 343px;
        width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        .inner {
            background-color: rgba(26, 26, 29, 0.02);
            border-radius: 100%;
            height: 283px;
            width: 283px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 184px;
                height: 184px;
            }
        }
    }

    .text {
        font-size: 14px;
        line-height: 20px;
        color: #303030;
        margin: 0 auto;
        width: 311px;
    }
}
.progressBar {
    width: 100%;
    border-radius: 10px;
    background-color: #EDEDED;
    position: relative;
    margin-bottom: 34px;
    height: 18px;
}

.inner {
    width: 100%;
    border-radius: 10px;
    background-color: #82D313;
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
}

.active {
    animation-name: timer;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes timer{
    0%{
        width: 100%;
    }
    100%{
        width: 0;
    }
}
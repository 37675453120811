body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

*::-webkit-scrollbar{
	display: none;	
}

#root {
  height: 100%;
}

html {
  height: 100%;
  outline: none;
}

.page-main{
  height: 100%;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transform: translateY(25px);
}

.fade-appear-active,
.fade-enter-active {
  transition: .5s;
  opacity: 1;
  transform: translateY(0);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  transition: .3s;
  transform: translateY(-25px);
  opacity: 0;
}

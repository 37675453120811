.alertScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gameName {
    color: #fff;
    margin-bottom: 55px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.circle {
    width: 343px;
    height: 343px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.innerCircle {
    width: 283px;
    height: 283px;
    border-radius: 100%;
    background-color: rgba(26, 24, 24, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 44px;
    line-height: 54px;
    color: #fff;
    font-weight: 700;
    text-align: center;
}

.subtitle {
    font-size: 44px;
    line-height: 54px;
    color: #fff;
    text-align: center;
    max-width: 1100px;
}
.dots {
    width: 100%;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 38px;
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #fff;
    margin-right: 16px;

    &.active {
        background-color: #FFCF27;
    }

    &.past {
        background-color: #00B736;
    }

    &:last-child {
        margin-right: 0;
    }
}
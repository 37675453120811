.controls {
    height: 18px;
    line-height: 18px;
    width: calc(100% - 48px);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dots {
    width: 100%;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #E6E6E6;
    margin-right: 10px;

    &.active {
        background-color: #00B736;
    }

    &:last-child {
        margin-right: 0;
    }
}

.button {
    font-size: 14px;
    min-width: 80px;
    line-height: 18px;
    font-weight: 700;
    color: #00BA37;
    text-transform: uppercase;

    &.next {
        text-align: right;
    }

    &.prev {
        text-align: left;
    }

    &.invisible {
        opacity: 0.3;
        pointer-events: none;
    }
}
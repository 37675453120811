.qrScreen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 45px 0 50px;
    box-sizing: border-box;
}

.usersCount {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #fff;

    span {
        font-weight: 400;
    }
}

.qrBackground {
    width: 80vw;
    height: 60vh;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 30px 30px #FF006C2B;
}

.qrCode {
    max-height: 56vh;
}

.url {
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #fff;
}

.questionScreen {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cusestionsCount {
    width: 100%;
    height: 29px;
    line-height: 29px;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    position: absolute;
    top: 45px;
    left: 0;
    text-align: center;

    span {
        font-weight: 400;
    }
}

.questionText{
    color: #fff;
    font-size: 62px;
    line-height: 72px;
    font-family: 'Noto Serif', serif;
    width: 100%;
    padding: 0 190px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 64px;
}

.options {
    width: 900px;
    height: 312px;
    background-color: #fff;
    box-shadow: 0 30px 30px #FF006C2B;
    box-sizing: border-box;
    padding: 40px 36px;
    margin-top: 64px;
    border-radius: 10px;
    position: relative;
}

.optionsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.button {
    display: block;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    width: calc(100% - 48px);
    text-align: center;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.64px;
    position: absolute;
    bottom: 24px;
    left: 24px;
    border-radius: 4px;
    background-color: #00AF20;
    font-weight: 700;
    letter-spacing: 0.64px;
    text-transform: uppercase;
}
.results {
    width: 835px;
    height: 74px;
    line-height: 74px;
    font-size: 36px;
    color: #303030;
    border-radius: 10px;
    text-align: center;
    font-weight: 400;
    position: absolute;
    top: -48px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 10px 10px #0000001A;

    span {
        font-weight: 700;
    }
}
.winnerScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gameName {
    color: #fff;
    margin-bottom: 44px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.icon {
    margin-bottom: 46px;
    width: 410px;
}

.title {
    color: #fff;
    // font-weight: 700;
    font-size: 62px;
    line-height: 72px;
    margin-bottom: 35px;
    font-family: 'Noto Serif', serif;
}

.stats {
    font-size: 52px;
    line-height: 100px;
    color: #fff;
    font-family: 'Noto Serif', serif;

    span {
        color: #00BE04;
        font-size: 82px;
    }
}

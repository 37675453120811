.tutorialScreen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 45px 0 50px;
    box-sizing: border-box;
    text-align: center;
}

.usersCount {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 45px;

    span {
        font-weight: 400;
    }
}

.title {
    font-family: 'Noto Serif', serif;
    font-size: 62px;
    line-height: 72px;
    color: #fff;
    margin-bottom: 45px;
}

.wrapper {
    width: 1194px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 497px;
}

.tutorialContainer {
    height: 100%;
    width: 685px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 38px;
    text-align: left;

    ol {
        font-size: 24px;
        line-height: 28px;
        color: rgba(26, 26, 29, 0.5);
        
        li {
            margin-bottom: 27px;
            padding-left: 12px;
            
            span {
                color: #1A1A1D;

                span {
                    font-weight: 700;
                }
            }
        }
    }
}

.codeContainer {
    width: 476px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;

    img {
        height: 100%;
        width: auto;
        max-width: 420px;
    }
}



.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 100px;
    box-sizing: border-box;
}

.eventName {
    font-size: 14px;
    line-height: 18px;
    color: #303030;
    font-weight: 700;
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: auto;
}

.form {
    width: calc(100% - 48px);
    background-color: #fff;
    box-shadow: 0 10px 30px #B1B1B147;
    padding: 48px 24px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 10px;
}

.title {
    color: #1A1818;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
}

input {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding-left: 20px;
    border: 1px solid #969696;
    outline: none;
    font-weight: 300;
    font-style: italic;
    color: #1A1A1D;
    font-size: 12px;
    margin-bottom: 23px;
    transition: 0.3s;

    &.error {
        border-color: #FE3B35;

        &::placeholder {
            color: #FE3B35;
        }
    }
}

.subtitle {
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    color: #1A1A1D;
    width: 232px;
    margin: 0 auto;
}
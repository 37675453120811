.initialScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gameName {
    color: #fff;
    margin-bottom: 33px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.icon {
    margin-bottom: 38px;
    width: 520px;
}

.title {
    color: #fff;
    font-weight: 700;
    font-size: 68px;
    line-height: 83px;
}

.subtitle {
    color: #fff;
    font-size: 34px;
    line-height: 20px;
}